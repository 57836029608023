<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        In the process of attempting to characterize a substance, a chemist makes a number of
        observations? Select the appropriate option to denote whether each of the following are
        representative of physical properties or chemical properties?
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Property</th>
            <th style="font-size: 15px">Physical</th>
            <th style="font-size: 15px">Chemical</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{The substance is a silvery white, lustrous metal}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex
                content="$\text{The substance burns in air, producing an intense white light}$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex
                content="$\text{It melts at }649^{\circ}\text{C and boils at } 1105^{\circ}\text{C}$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex
                content="$\text{Its density at }20^{\circ}\text{C is } 1.783\text{g/cm}^3$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex
                content="$\text{It reacts with chlorine to give a brittle white solid}$"
              />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="physical" value="physical" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="chemical" value="chemical" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question182',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
};
</script>
